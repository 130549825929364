import axios from "../axios";
const ID_TOKEN_KEY = "token";
const auth = {
  emailRegistration(data,lang) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/register/${lang}`, data)
        .then((response) => {
          resolve(response);
          this.saveToken(response?.data?.token);
        })
        .catch((error) => reject(error));
    });
  },

  verifyOTP(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/verify", data)
        .then((response) => {
          resolve(response);
          this.saveToken(response?.data?.token);
        })
        .catch((error) => reject(error));
    });
  },

  setPassword(data,lang) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`/set-password/${lang}`, data)
        .then((response) => {
          this.saveToken(response?.data?.token);
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  login(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/login", data)
        .then((response) => {
          resolve(response);
          this.saveToken(response?.data?.token);
        })
        .catch((error) => reject(error));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("/logout")
        .then(({ data }) => {
          this.destroyToken("dis");

          resolve(data);
        })
        .catch(({ response }) => {
          this.destroyToken("dis");
          reject(response);
        });
    });
  },
  forgetPassword(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("/forgot-password", data)
        .then(({ data }) => {
          this.destroyToken();

          resolve(data);
        })
        .catch(({ response }) => {
          this.destroyToken();
          reject(response);
        });
    });
  },
  resetPassword(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("/password-reset", data)
        .then(({ data }) => {
          this.destroyToken();

          resolve(data);
        })
        .catch(({ response }) => {
          this.destroyToken();
          reject(response);
        });
    });
  },
  getAuthUserData() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("/get-auth-user")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log('statsu',response);
          response?.status === 401 && this?.destroyToken();
          reject(response);
        });
    });
  },
  getNotification() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("get-notification")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  cleartNotification() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete("delete-notification")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  getSupportData() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("/get-support-messages")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  GptChatBot(data) {
    console.log('data',data);
    
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("/GptChat",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  getSupportDataById(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`/get-support-messages/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  sendQueryEmail(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`/send-support-email`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  setLanguage(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`/users/change-lang`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  unsubscrbeEmail(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`unsubscribe-email/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  popupStatus() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`/popup-status`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  getContactBot() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("/getConatctUsBot")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  },
  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  },
  destroyToken(distory) {
    if (distory) {
      window.localStorage.removeItem(ID_TOKEN_KEY);
    } else {
      window.localStorage.removeItem(ID_TOKEN_KEY);
      window.location.href = "/login";
    }
  },
};

export default auth;
