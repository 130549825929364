import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/courses.js";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

function Courses({ data }) {
  const { t,i18n } = useTranslation();
  const history = useHistory();
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  return (
    <Styles>
      {/* About Us 2 */}
      {data?.length > 0 && (
        <section className="courses py-2">
          <Container>
            <Col md="12">
              <div className="sec-title text-center  ">
                <h4 className="mb-4 ">{t("ourSubjects")}</h4>
                <p className="">{t("ourSubjectsPara")}</p>
              </div>
            </Col>
            <Col
              md="12"
              className={`scroll-conatiner d-flex ${
                data?.length === 5 ? "justify-content-lg-between" : "gap-5"
              }  mt-5 flex-row gap-5`}
            >
              {data?.map((item) => {
                return (
                  <div className="card-box border rounded-3 col-lg-2 col-md-4 col-10  d-flex align-items-center justify-content-evenly flex-column py-3 shadow-sm    ">
                    <p className="sub-name">{i18n?.language==='fr'?item?.subject_name_fr:item?.subject_name}</p>
                    <p>
                      {item?.count} {t("courses")}
                    </p>
                    {authUserData?.userType !== 1 && (
                      <button
                        onClick={() => {
                          authUserData
                            ? history.push({
                                pathname: "/searchTeacher",
                                state: {
                                  subject_id: item?.subject_id,
                                  level_id: "course",
                                },
                              })
                            : history.push("/login");
                        }}
                        aria-label="sreach-teacher"
                      >
                        {t("hireTutor")}
                      </button>
                    )}
                  </div>
                );
              })}
            </Col>

            <Col md={12} className="text-center mt-5">
              <button
                className="px-4"
                onClick={() => {
                  authUserData
                    ? history.push("/dashboard")
                    : history.push("/login");
                }}
                aria-label="browseSub"
              >
                {t("browseSubjects")}
              </button>
            </Col>
          </Container>
        </section>
      )}
    </Styles>
  );
}

export default Courses;
