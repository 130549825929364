export function sendPushNotification(message) {
    Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification(
            message,
            {
              icon:  process.env.PUBLIC_URL + "/assets/images/smallLogo.webp",
            }
          );
        } else if (permission === "denied") {
            console.log("La notification est désactivée");
        } else {
          console.log("Notification permission dismissed.");
        }
      });
}