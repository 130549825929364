import React, { useEffect, useState, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global.js";
import { SnackbarProvider } from "notistack";
import notistack, { SnackbarUtilsConfigurator } from "./puglins/notistack";
import { subjectGrade } from "./puglins/Utils/models";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthUser,
  getContactBot,
  getGradeFailure,
  getGradeSuccess,
  getLanguageFailure,
  getLanguageSuccess,
  getNotificationData,
  getSocket,
  getSubjectFailure,
  getSubjectSuccess,
  isLogin,
} from "./actions/initialData";
import { getClassHistory } from "./actions/student.js";
import "./locales/i18n";
import { DarkModeProvider, useDarkMode } from "@rbnd/react-dark-mode";
import noNetworkAction from "./puglins/Utils/errorManage.js";
import { useTranslation } from "react-i18next";
import { onGoingClassDetails } from "./actions/teacher.js";
import Loader from "./components/common/Loader.js";
import MainRoutes from "./routes/index.js";
import { useIdleTimer } from "react-idle-timer";
import { sendPushNotification } from "./puglins/pushNotification.js";

const ChatBot = lazy(() => import("./components/common/ChatBot.js"));
const ResumeMeeting = lazy(() =>
  import("./components/common/ResumeMeeting.js")
);
const Alert = lazy(() => import("./components/Alert.js"));

function App() {
  const dispatch = useDispatch();
  const { authUserData, socket } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const { onClassDetails } = useSelector(
    ({ teacherReducer }) => teacherReducer
  );
  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();
  const [openChat, setOpenChat] = useState(false);
  const [bottom, setBottom] = useState(20);
  const [loader, setLoader] = useState(true);
  const [paymentModel, setPaymentModel] = useState({
    classId: null,
    model: false,
  });

  let audio;
  const { setMode } = useDarkMode();
  const history = useHistory();
  const onClassDetailsData=onClassDetails;

  // To get Initial data on page load on Initaial state
  useEffect(() => {

    dispatch(getSocket());
    const fetchData = async () => {
      setLoader(true);
      const getGrades = await dispatch(getGrade());
      const getLanguagess = await dispatch(getLanguages());
      const getSubjectss = await dispatch(getSubjects());
      manageDomainSpecificLang();
    };
    fetchData().then(() => {
      setTimeout(() => setLoader(false), 1000);
    });

    // Check the current system dark theme
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setMode("dark");
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  //  Socket Io connection and action to perform on connection
  useEffect(() => {
    localStorage.getItem("token") && dispatch(isLogin(true));
    if (authUserData) {
      dispatch(getNotificationData(history));
      dispatch(getContactBot(history));
      if(authUserData?.online === 2){
        dispatch(getClassHistory(history, authUserData?._id));
        // const onClassData=JSON.parse(sessionStorage.getItem('classDetails'));
        // console.log('onClassData',onClassData);
        
        // Object.keys(onClassData).length>0&&dispatch(
        //   onGoingClassDetails(onClassData)
        // );
      }
      socket?.on("connect", () => {
        console.log("Connected to the serverasdsad1212");
      });
      socket?.emit("joinRoom", authUserData?._id);
    }

    // ---- To listen class request sent to teacher event
    socket?.on("class-request", (data) => {
      // Lazy load and play audio only when needed
      const playAudio = async () => {
        if (!audio) {
          const audioModule = await import("./audio/notification.mp3"); // Lazy load audio when evenys hit
          audio = new Audio(audioModule.default);
        }
        audio.play();
      };
      playAudio();
      notistack.toast(data?.message);
      sendPushNotification(data?.message)
      dispatch(getClassHistory(history, authUserData?._id));
      dispatch(getNotificationData());
      // authUserData?.online === 2 &&
      setPaymentModel((prev) => ({
        ...prev,
        classId: data?.class_information,
        model: true,
        modelNo: 5,
      }));
      sessionStorage.setItem("RequestId", data?.class_information?._id);
    });

    // ---- To listen event when teacher accpet request
    socket?.on("invitation-accepted", (data) => {
      notistack.toast(data?.message);
      setPaymentModel((prev) => ({
        ...prev,
        classId: data,
        model: true,
        modelNo: 1,
      }));
      dispatch(getClassHistory(history, authUserData?._id));
      dispatch(getNotificationData());
    });

    // ---- To listen event when teacher reject request

    socket?.on("invitation-rejected", (data) => {
      notistack.error(data?.message);
      setPaymentModel((prev) => ({
        ...prev,
        classId: data?.class_id,
        model: true,
        modelNo: 3,
        advertData: data?.adverts,
      }));
      dispatch(getClassHistory(history, authUserData?._id));
      dispatch(getAuthUser(history));
      dispatch(getNotificationData());
    });

    // ---- To listen event when class is completed and nitify both teacher and student

    socket?.on("class-completed", (data) => {
      setPaymentModel((prev) => ({
        ...prev,
        classId: data,
        model: true,
        modelNo: 4,
      }));
      notistack.toast(t("notiClassCompleted"));
      // dispatch(getClassHistory(history, authUserData?._id));
      dispatch(getNotificationData());
      dispatch(onGoingClassDetails(null));
      // dispatch(getAuthUser());
    });
    socket?.on("class-started", (data) => {
      window.location.pathname !== "/ZoomClasses" &&
        notistack.toast(t("notiTutorStarted"));
      dispatch(getClassHistory(history, authUserData?._id));
      dispatch(getNotificationData());
    });
    authUserData?.userType === 2 &&
      socket?.on("favourite-teacher", (data) => {
        notistack.toast(data?.message);
        dispatch(getNotificationData());
      });
    socket?.on("level-upgrade", (data) => {
      notistack.toast(data?.message);
      dispatch(getNotificationData());
    });
    socket?.on("exit-waiting-room", (data) => {
      notistack.toast(data?.message);
      dispatch(getNotificationData());
    });
    socket?.on("wallet-recharge-admin", (data) => {
      notistack.toast(data?.message);
      dispatch(getAuthUser(history));
    });
    socket?.on("waiting-room-start-soon", (data) => {
      notistack.toast(data?.message);
      const playAudio = async () => {
        if (!audio) {
          const audioModule = await import("./audio/notification2.mp3"); // Lazy load audio
          audio = new Audio(audioModule.default);
        }
        audio.play();
      };
      playAudio();
      dispatch(getNotificationData());
    });
    socket?.on("invitation-auto-rejected", (data) => {
      sessionStorage?.getItem("RequestId") === data?.data &&
        setPaymentModel((prev) => ({
          ...prev,
          classId: null,
          model: false,
          modelNo: 0,
        }));
      dispatch(getClassHistory(history, authUserData?._id));
    });

    return () => {
      socket?.off("class-request");
      socket?.off("invitation-accepted");
      socket?.off("invitation-rejected");
      socket?.off("class-completed");
      socket?.off("class-started");
      socket?.off("favourite-teacher");
      socket?.off("joinRoom");
      socket?.off("waiting-room-start-soon");
      socket?.off("exit-waiting-room");
      socket?.off("invitation-auto-rejected");
      socket?.off("wallet-recharge-admin");
    };
  }, [socket, authUserData]);

  // To Manage scroll event to show auto scroll up oyion
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 750) {
        setBottom(110);
      } else {
        setBottom(20);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup  event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // To Get Ongoing Class details
  // useEffect(() => {

  //   if (authUserData?.online === 2 && classHistory?.length) {
  //     dispatch(
  //       onGoingClassDetails({
  //         token:
  //           authUserData?.userType == 1
  //             ? classHistory[0]?.start_meeting_url
  //             : classHistory[0]?.join_meeting_url,
  //         sessionName:
  //           classHistory[0]?.advert_id?.subject_expertise?.subject_name,
  //         id: classHistory[0]?._id,
  //       })
  //     );
  //   }
  // }, [authUserData,classHistory]);

  // To check if the window close and hit api to change teacher status
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pagehide", (event) => {
      if (!event.persisted) {
        handleUnload();
      }
    });



    // Cleanup event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("pagehide", handleUnload);

    };
  }, [authUserData]);

  const handleUnload = (idle) => {
    console.log('onClassDetails-----',authUserData);

    const url =
      process.env.REACT_APP_URL_API +
      `/update-online-status/${authUserData?._id}/${authUserData?.online===2?2:0}`;
    const data = { data: authUserData?._id };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      keepalive: true,
    })
      .then((response) => idle && dispatch(getAuthUser(history)))
      .catch((error) => console.error("Error sending data:", error));

    if (authUserData?.userType === 1) {
      sessionStorage.setItem(
        "online",
        JSON.stringify({
          status: authUserData?.online,
          user_id: authUserData?._id,
        })
      );      
    }
    
    if (socket) {
      socket.disconnect();
    }
  };

  // Handle dialog box before close the page onky form zoom meeting
  const handleBeforeUnload = (event) => {
    // if(authUserData?.online===2){
      
    //   sessionStorage.setItem(
    //     "classDetails",
    //     JSON.stringify({
    //       ...onClassDetailsData
    //     })
    //   );
    // }
    
    if (window.location.pathname === "/ZoomClasses") {
      event.preventDefault();
      event.returnValue = t("leaveZoomAlert");

    }
  
  };

  // The Action to go Offline when the teacher is In-active for 2 hrs ( No action on the platform)
  const onIdleFunction = () =>
    authUserData?.userType === 1 &&
    authUserData?.online === 1 &&
    handleUnload("idle");
    
  const idleTimer = useIdleTimer({
    timeout: 900000,
    onIdle: onIdleFunction,
    debounce: 500,
  });

  // ------To get langauges Intially and store it in redux
  const getLanguages = () => {
    return (dispatch) => {
      subjectGrade
        .getLangauge()
        .then((response) => {
          dispatch(getLanguageSuccess(response?.data));
        })
        .catch((error) => {
          error?.message === "Network Error" && noNetworkAction(history);
          dispatch(getLanguageFailure(error));
          console.log("Error while Getting Language", error);
        });
    };
  };
  // ------To get grade Intially and store it in redux
  const getGrade = () => {
    return (dispatch) => {
      subjectGrade
        .getGrade()
        .then((res) => {
          dispatch(getGradeSuccess(res?.data));
        })
        .catch((err) => {
          err?.message === "Network Error" && noNetworkAction(history);
          dispatch(getGradeFailure(err));
          console.log("Error while fetching grades", err);
        });
    };
  };
  // ------To get subject Intially and store it in redux
  const getSubjects = () => {
    return (dispatch) => {
      subjectGrade
        .getSubjects()
        .then((res) => {
          dispatch(getSubjectSuccess(res?.data));
        })
        .catch((err) => {
          err?.message === "Network Error" && noNetworkAction(history);
          dispatch(getSubjectFailure(err));
          console.log("Error while fetching subject", err);
        });
    };
  };

  // To manage default language on domin sepecific
  const manageDomainSpecificLang = () => {
    if (window.location.origin.includes(".fr")) {
      changeLanguage("fr");
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <DarkModeProvider>
          <Router>
            <SnackbarProvider maxSnack={5} style={{ fontSize: "14px" }}>
              <SnackbarUtilsConfigurator />
              <GlobalStyle />
              <ScrollToTop />
              <Switch>
                <Route>
                  <MainRoutes />
                </Route>
              </Switch>
              {paymentModel?.model && (
                <Suspense>
                  <Alert
                    paymentModel={paymentModel}
                    setPaymentModel={setPaymentModel}
                  />
                </Suspense>
              )}
              {authUserData && (
                <>
                  {window.location.pathname !== "/ZoomClasses" &&
                    onClassDetails && (
                      <Suspense fallback={<div>...</div>}>
                        <ResumeMeeting bottom={bottom} />
                      </Suspense>
                    )}
                  <Suspense fallback={<div>...</div>}>
                    <ChatBot
                      bottom={bottom}
                      openChat={openChat}
                      setOpenChat={setOpenChat}
                    />
                  </Suspense>
                </>
              )}
            </SnackbarProvider>
          </Router>
        </DarkModeProvider>
      )}
    </>
  );
}

export default App;
