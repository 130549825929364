import styled from "styled-components";
import { colors } from "../common/element/elements.js";

const width = window.innerWidth - 50;
const height = window.innerHeight;
export const Styles = styled.div`
.side-panel-container {
    //   position: relative;
    }
    .side-panel {
      position: absolute;
      top: 0px;
      left: 0px;
      opacity:1;
      color: white;
      width:100%;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
      z-index:9999;
      position: fixed;


      .panel-container{
        background-color: ${colors?.darkblue} !important;
        width: 250px;
        height: 100vh;
      }

     
    }
    .side-panel.open {
      transform: translateX(0%);

    }
    .side-panel.closed {
      transform: translateX(-110%);
    }
    .side-panel ul {
      list-style: none;
      padding: 0;
      margin-top: 10%;
    }
    .side-panel li {
      padding: 25px 25px;
      cursor: pointer;
    }
    .side-panel li:hover {
      background-color: #575757;
    }
    .nav-btn{
        margin-bottom:20px;
        padding: 10px;
        color:#fff;
        font-weight: 500;
        padding-left: 10%;


    }
    .active{
       background:${colors?.orange};
        color:#fff;
        font-weight: 500;
       


    }
}
`;
